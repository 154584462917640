import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AnimationBuilder, AnimationMetadata, animate, style } from '@angular/animations';
import { timer } from 'rxjs';

@Directive({
  selector: '[hbButtonStateAnimation]',
  standalone: true
})
export class ButtonStateAnimationDirective {
  @Input() public originalColor: string = 'var(--color-gold)'; 
  @Input() public clickedColor: string = 'var(--color-blue-latest)'; 
  private buttonState = "original";

  constructor(private builder: AnimationBuilder, private el: ElementRef) {}

  @HostListener('click', ['$event'])
  public onClick(): void {
    this.buttonState = 'clicked';

    timer(1000).subscribe(() => {
      this.buttonState = 'original';
    });

    this.playAnimation();
  }

  private animate(color: string): AnimationMetadata[] {
    return [
      style({ color }),
      animate('0.5s'),
    ];
  }

  private playAnimation(): void {
    const metadata = this.animate(this.buttonState === 'original' ? this.originalColor : this.clickedColor);
    const factory = this.builder.build(metadata);
    const player = factory.create(this.el.nativeElement);
    player.play();
  }
}
